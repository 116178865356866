export interface CharityInterface {
  id: number
  name: string
  description: string | null
  logo: string | null
  website: string | null
}

export class Charity implements CharityInterface {
  id: number
  name: string
  description: string | null
  logo: string | null
  website: string | null

  constructor(copyFrom: CharityInterface) {
    this.id = copyFrom.id
    this.name = copyFrom.name
    this.description = copyFrom.description
    this.logo = copyFrom.logo
    this.website = copyFrom.website
  }

  toPOJO(): CharityInterface {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      logo: this.logo,
      website: this.website
    }
  }
}

import { useRuntimeConfig } from '#app'
import Keycloak, { type KeycloakTokenParsed } from 'keycloak-js'

export class KeycloakService {
  private static instance: KeycloakService
  private config: {
    public: { keycloak: { clientId: string; realm: string; url: string } }
  } = useRuntimeConfig() as {
    public: { keycloak: { clientId: string; realm: string; url: string } }
  }
  private status: 'not-started' | 'started' | 'running' = 'not-started'
  private authenticated: boolean = false
  private keycloak: Keycloak
  private keepAliveInterval: number | null = null
  private keycloakOptions: {
    onLoad: 'login-required' | 'check-sso'
    silentCheckSsoRedirectUri: string | undefined
    checkLoginIframe: boolean
    silentCheckSsoFallback: boolean | undefined
  } = {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    checkLoginIframe: false,
    silentCheckSsoFallback: false
  }

  private constructor() {
    console.info('Starting Keycloak using the following configuration', {
      url: this.config.public.keycloak.url,
      realm: this.config.public.keycloak.realm,
      clientId: this.config.public.keycloak.clientId
    })

    this.keycloak = new Keycloak({
      url: this.config.public.keycloak.url,
      realm: this.config.public.keycloak.realm,
      clientId: this.config.public.keycloak.clientId
    })

    // todo we need to upgrade keycloak to version 22.x in the hope that this is fixed.
    function isSafari() {
      // Check if the User Agent string contains 'Safari'
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    }

    if (isSafari()) {
      console.warn('Safari detected, forcing login required')
      this.keycloakOptions.onLoad = isSafari() ? 'login-required' : 'check-sso'
      delete this.keycloakOptions.silentCheckSsoFallback
      delete this.keycloakOptions.silentCheckSsoRedirectUri
      console.warn('Safari detected, forcing login required, options', this.keycloakOptions)
    }
  }

  static getInstance() {
    if (!KeycloakService.instance) {
      KeycloakService.instance = new KeycloakService()
    }
    return KeycloakService.instance
  }

  async init() {
    if (this.status !== 'not-started') {
      console.warn(
        'Keycloak already started, ignoring subsequent calls. Refresh the page to restart'
      )
      return this.authenticated
    }

    this.status = 'started'
    return await this.keycloak
      .init(this.keycloakOptions)
      .then((authenticated) => {
        this.authenticated = authenticated
        this.status = 'running'
        if (authenticated) {
          console.log('User is authenticated, token:', this.keycloak.token)
          this.startTokenAlive()
        }
        return authenticated
      })
      .catch((error) => {
        console.error('Error initializing Keycloak', error)
      })
  }

  async isAuthenticated() {
    if (this.status === 'not-started') {
      await this.init()
    }

    if (this.status === 'running') {
      return this.authenticated
    }

    // watch for this.initialized to be change with a timeout of 5 seconds
    const timeout = 5000
    const interval = 100
    const maxTries = timeout / interval
    let tries = 0
    while (this.status !== 'started' && tries < maxTries) {
      await new Promise((resolve) => setTimeout(resolve, interval))
      tries++
    }

    return this.authenticated
  }

  async updateToken(minValidity: number) {
    console.log('Updating token, minValidity:', minValidity)
    try {
      await this.keycloak.updateToken(minValidity)
    } catch (exception: unknown) {
      console.error('Error updating token', exception)
      throw exception
    }
  }

  async login(redirectUri?: string) {
    if (!redirectUri) {
      redirectUri = window.location.href
    }
    const options = Object.assign({}, this.keycloakOptions, { redirectUri })
    await this.keycloak.login(options)
  }

  async logout() {
    await this.keycloak.logout()
  }

  getUser(): KeycloakTokenParsed | undefined {
    return this.keycloak.tokenParsed
  }

  getToken() {
    return this.keycloak.token
  }

  private startTokenAlive() {
    console.log('Starting token alive')
    this.stopTokenAlive()

    this.keepAliveInterval = window.setInterval(
      async () => {
        await this.updateToken(10)
      },
      10 * 60 * 1000
    )
  }

  private stopTokenAlive() {
    if (this.keepAliveInterval != null) {
      console.log('Stopping token alive')
      clearInterval(this.keepAliveInterval)
      this.keepAliveInterval = null
    }
  }
}

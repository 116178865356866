export interface FuneralCompanyInterface {
  id: number
  name: string
  logo: number
  logo_path: string
  primary_color: string
  secondary_color: string
  created_at: string
  updated_at: string
  location_id: number
  active_registers: number
  inactive_registers: number
}

export class FuneralCompany implements FuneralCompanyInterface {
  id: number
  name: string
  logo: number
  logo_path: string
  primary_color: string
  secondary_color: string
  created_at: string
  updated_at: string
  location_id: number
  active_registers: number
  inactive_registers: number

  constructor(copyFrom: FuneralCompanyInterface) {
    this.id = copyFrom.id
    this.name = copyFrom.name
    this.logo = copyFrom.logo
    this.logo_path = copyFrom.logo_path
    this.primary_color = copyFrom.primary_color
    this.secondary_color = copyFrom.secondary_color
    this.created_at = copyFrom.created_at
    this.updated_at = copyFrom.updated_at
    this.location_id = copyFrom.location_id
    this.active_registers = copyFrom.active_registers
    this.inactive_registers = copyFrom.inactive_registers
  }

  toPOJO(): FuneralCompanyInterface {
    return {
      id: this.id,
      name: this.name,
      logo: this.logo,
      logo_path: this.logo_path,
      primary_color: this.primary_color,
      secondary_color: this.secondary_color,
      created_at: this.created_at,
      updated_at: this.updated_at,
      location_id: this.location_id,
      active_registers: this.active_registers,
      inactive_registers: this.inactive_registers
    }
  }
}

export interface GestureInterface {
  id: number
  register_id: number
  visitor_id: number
  ip_address: string
  created_at: string
  updated_at: string
  type: string
}

export class Gesture implements GestureInterface {
  id: number
  register_id: number
  visitor_id: number
  ip_address: string
  created_at: string
  updated_at: string
  type: string

  constructor(copyFrom: GestureInterface) {
    this.id = copyFrom.id
    this.register_id = copyFrom.register_id
    this.visitor_id = copyFrom.visitor_id
    this.ip_address = copyFrom.ip_address
    this.created_at = copyFrom.created_at
    this.updated_at = copyFrom.updated_at
    this.type = copyFrom.type
  }

  getCreatedAt(): string {
    return new Intl.DateTimeFormat('nl-NL', { dateStyle: 'long' }).format(new Date(this.created_at))
  }

  getUpdatedAt(): string {
    return new Intl.DateTimeFormat('nl-NL', { dateStyle: 'long' }).format(new Date(this.updated_at))
  }

  toPOJO(): GestureInterface {
    return {
      id: this.id,
      register_id: this.register_id,
      visitor_id: this.visitor_id,
      ip_address: this.ip_address,
      created_at: this.created_at,
      updated_at: this.updated_at,
      type: this.type
    }
  }
}

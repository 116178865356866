export interface MonumentInterface {
  id: number
  location_id: number
  name: string
  slug: string
  type: string
  latitude: number
  longitude: number
  created_at: string
  updated_at: string
}

export class Monument implements MonumentInterface {
  id: number
  location_id: number
  name: string
  slug: string
  type: string
  latitude: number
  longitude: number
  created_at: string
  updated_at: string

  constructor(copyFrom: MonumentInterface) {
    this.id = copyFrom.id
    this.location_id = copyFrom.location_id
    this.name = copyFrom.name
    this.slug = copyFrom.slug
    this.type = copyFrom.type
    this.latitude = copyFrom.latitude
    this.longitude = copyFrom.longitude
    this.created_at = copyFrom.created_at
    this.updated_at = copyFrom.updated_at
  }

  toPOJO(): MonumentInterface {
    return {
      id: this.id,
      location_id: this.location_id,
      name: this.name,
      slug: this.slug,
      type: this.type,
      latitude: this.latitude,
      longitude: this.longitude,
      created_at: this.created_at,
      updated_at: this.updated_at
    }
  }
}

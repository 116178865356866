import type { Operator } from '~/util/filter/operator'

export interface FilterOptions {
  page?: number
  itemsPerPage?: number
  search?: string | null
  sort?: string | null
  order?: 'asc' | 'desc' | null
  filters?: Operator[] | null
}

export class Filter {
  constructor(filterOptions: FilterOptions) {
    this._page = filterOptions.page ?? this._page
    this._itemsPerPage = filterOptions.itemsPerPage ?? this._itemsPerPage
    this._search = filterOptions.search ?? this._search
    this._sort = filterOptions.sort ?? this._sort
    this._order = filterOptions.order ?? this._order
    this._filters = filterOptions.filters ?? this._filters
  }

  private _page: number = 1

  get page(): number {
    return this._page
  }

  private _itemsPerPage: number = 10

  get itemsPerPage(): number {
    return this._itemsPerPage
  }

  private _search: string | null = null

  get search(): string | null {
    return this._search
  }

  private _sort: string | null = null

  get sort(): string | null {
    return this._sort
  }

  private _order: 'asc' | 'desc' | null = null

  get order(): 'asc' | 'desc' | null {
    return this._order
  }

  private _filters: Operator[] | null = null

  get filters(): Operator[] | null {
    return this._filters
  }

  getQueryString() {
    const query = new URLSearchParams()

    if (this.page) {
      query.append('page', this.page.toString())
    }

    if (this.itemsPerPage) {
      query.append('limit', this.itemsPerPage.toString())
    }

    if (this.search) {
      query.append('search', this.search)
    }

    if (this.sort) {
      query.append('sort', this.sort)
    }

    if (this.order) {
      query.append('order', this.order)
    }

    if (this.filters) {
      const filters = this.filters.map((filter) => filter.toObject())
      const json = JSON.stringify(filters)
      const base64 = btoa(json)
      query.append('filter', base64)
    }

    return query.toString()
  }
}

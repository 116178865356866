import type { Operator } from '~/util/filter/operator'

/**
 * Basic JSON structure looks like this:
 * [
 *      [
 *          'name' => 'first_name',
 *          'operator' => '=',
 *          'value' => 'John'
 *      ]
 * ]
 */
export class EqualOperator implements Operator {
  public constructor(
    private readonly fieldName: string,
    private readonly value: string | number | boolean
  ) {}

  public toObject(): object {
    return {
      name: this.fieldName,
      value: this.value,
      operator: '='
    }
  }
}

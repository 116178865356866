<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <UNotifications class="notifications" />
</template>

<script lang="ts" setup>
import { useI18n } from '#imports'

const { locale, setLocale } = useI18n()
console.debug('App: Current locale:', locale.value)
</script>

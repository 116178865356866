export interface VisitorInterface {
  id: number
  created_at: string
  updated_at: string
  firstname: string
  lastname: string
  email: string
  email_verified_at: string
}

export class Visitor implements VisitorInterface {
  id: number
  created_at: string
  updated_at: string
  firstname: string
  lastname: string
  email: string
  email_verified_at: string

  constructor(copyFrom: VisitorInterface) {
    this.id = copyFrom.id
    this.created_at = copyFrom.created_at
    this.updated_at = copyFrom.updated_at
    this.firstname = copyFrom.firstname
    this.lastname = copyFrom.lastname
    this.email = copyFrom.email
    this.email_verified_at = copyFrom.email_verified_at
  }

  getCreatedAt(): string {
    return new Intl.DateTimeFormat('nl-NL', { dateStyle: 'long' }).format(new Date(this.created_at))
  }

  getUpdatedAt(): string {
    return new Intl.DateTimeFormat('nl-NL', { dateStyle: 'long' }).format(new Date(this.updated_at))
  }

  getEMailVerifiedAt(): string {
    return new Intl.DateTimeFormat('nl-NL', { dateStyle: 'long' }).format(
      new Date(this.email_verified_at)
    )
  }

  toPOJO(): VisitorInterface {
    return {
      id: this.id,
      created_at: this.created_at,
      updated_at: this.updated_at,
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      email_verified_at: this.email_verified_at
    }
  }
}

import { EntityReviver } from '~/util/entity-reviver'

export class ApiGetResponse<T extends { toPOJO(): object }> {
  constructor(item: object, class_: string) {
    this._class = class_
    this._item = this.reviveItem(item)
  }

  private _item: T

  get item(): T {
    return this._item
  }

  private _class: string

  get class(): string {
    return this._class
  }

  getSSRReducedResponse() {
    return {
      item: this._item.toPOJO(),
      class: this._class
    }
  }

  private reviveItem(item: object): T {
    const entityReviver = new EntityReviver<T>()
    try {
      return entityReviver.revive(this.class, item)
    } catch (e) {
      console.error('ApiGetResponse: Error reviving item', e)
      return entityReviver.revive(this.class, {})
    }
  }
}

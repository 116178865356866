import { EntityReviver } from '~/util/entity-reviver'

export class ApiFilterResponse<T extends { toPOJO(): object }> {
  constructor(items: object[], number_of_items: number, total: number, class_: string) {
    this._class = class_
    this._items = this.reviveItems(items)
    this._number_of_items = number_of_items
    this._total = total
  }

  private _items: T[]

  get items(): T[] {
    return this._items
  }

  private _number_of_items: number

  get number_of_items(): number {
    return this._number_of_items
  }

  private _total: number

  get total(): number {
    return this._total
  }

  private _class: string

  get class(): string {
    return this._class
  }

  getSSRReducedResponse() {
    return {
      items: this._items.map((item) => item.toPOJO()),
      number_of_items: this._number_of_items,
      total: this._total,
      class: this._class
    }
  }

  private reviveItems(items: object[]): T[] {
    try {
      const entityReviver = new EntityReviver<T>()
      return items.map((item) => entityReviver.revive(this.class, item))
    } catch (error) {
      console.error('ApiFilterResponse: Error reviving items', error)
      return []
    }
  }
}

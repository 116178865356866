export interface MediaInterface {
  id: number
  file_path: string | null
  file_url: string | null
  video_url: string | null
  type: string | null
  description: string | null
}

export class Media implements MediaInterface {
  id: number
  file_path: string | null
  file_url: string | null
  video_url: string | null
  type: string | null
  description: string | null

  constructor(copyFrom: MediaInterface) {
    this.id = copyFrom.id
    this.file_path = copyFrom.file_path
    this.file_url = copyFrom.file_url
    this.video_url = copyFrom.video_url
    this.type = copyFrom.type
    this.description = copyFrom.description
  }

  toPOJO(): MediaInterface {
    return {
      id: this.id,
      file_path: this.file_path,
      file_url: this.file_url,
      video_url: this.video_url,
      type: this.type,
      description: this.description
    }
  }
}

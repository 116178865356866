export interface CommentInterface {
  id: number
  register_id: number
  visitor_id: number
  created_at: string
  updated_at: string
  comment: string
  type: string
  gestures: number
  author_firstname: string | null
  author_lastname: string | null
  image_path: string
}

export class Comment implements CommentInterface {
  id: number
  register_id: number
  visitor_id: number
  created_at: string
  updated_at: string
  comment: string
  type: string
  gestures: number
  author_firstname: string | null
  author_lastname: string | null
  image_path: string

  constructor(copyFrom: CommentInterface) {
    this.id = copyFrom.id
    this.register_id = copyFrom.register_id
    this.visitor_id = copyFrom.visitor_id
    this.created_at = copyFrom.created_at
    this.updated_at = copyFrom.updated_at
    this.comment = copyFrom.comment
    this.type = copyFrom.type
    this.gestures = copyFrom.gestures
    this.author_firstname = copyFrom.author_firstname
    this.author_lastname = copyFrom.author_lastname
    this.image_path = copyFrom.image_path
  }

  getCreatedAt(): string {
    return new Intl.DateTimeFormat('nl-NL', { dateStyle: 'long' }).format(new Date(this.created_at))
  }

  getUpdatedAt(): string {
    return new Intl.DateTimeFormat('nl-NL', { dateStyle: 'long' }).format(new Date(this.updated_at))
  }

  getAuthorName(): string {
    return `${this.author_firstname ?? ''} ${this.author_lastname ?? ''}`.trim()
  }

  toPOJO(): CommentInterface {
    return {
      id: this.id,
      register_id: this.register_id,
      visitor_id: this.visitor_id,
      created_at: this.created_at,
      updated_at: this.updated_at,
      comment: this.comment,
      type: this.type,
      gestures: this.gestures,
      author_firstname: this.author_firstname,
      author_lastname: this.author_lastname,
      image_path: this.image_path
    }
  }
}

export interface FuneralLocationInterface {
  id: number
  funeral_company_id: number
  name: string
  street: string
  postal_code: string
  city: string
  latitude: number | null
  longitude: number | null
  image_id: number | null
  location_image_url: string | null
  created_at: string
  updated_at: string
}

export class FuneralLocation implements FuneralLocationInterface {
  id: number
  funeral_company_id: number
  name: string
  street: string
  postal_code: string
  city: string
  latitude: number | null
  longitude: number | null
  image_id: number | null
  location_image_url: string | null
  created_at: string
  updated_at: string

  constructor(copyFrom: FuneralLocationInterface) {
    this.id = copyFrom.id
    this.funeral_company_id = copyFrom.funeral_company_id
    this.name = copyFrom.name
    this.street = copyFrom.street
    this.postal_code = copyFrom.postal_code
    this.city = copyFrom.city
    this.latitude = copyFrom.latitude
    this.longitude = copyFrom.longitude
    this.image_id = copyFrom.image_id
    this.location_image_url = copyFrom.location_image_url
    this.created_at = copyFrom.created_at
    this.updated_at = copyFrom.updated_at
  }

  toPOJO(): FuneralLocationInterface {
    return {
      id: this.id,
      funeral_company_id: this.funeral_company_id,
      name: this.name,
      street: this.street,
      postal_code: this.postal_code,
      city: this.city,
      latitude: this.latitude,
      longitude: this.longitude,
      image_id: this.image_id,
      location_image_url: this.location_image_url,
      created_at: this.created_at,
      updated_at: this.updated_at
    }
  }
}

export interface FuneralInformationInterface {
  id: number
  register_id: number
  title: string
  date: string // ISO 8601 format
  start_time: string // ISO 8601 format
  end_time: string | null // ISO 8601 format
  description: string | null
  category: 'Voor de uitvaart' | 'Dag van de uitvaart' | 'Na de uitvaart'
  is_private: boolean
  external_link: string | null
  external_text: string | null
  image_id: number | null
  image_url: string | null
  location_id: number | null
}

export class FuneralInformation implements FuneralInformationInterface {
  id: number
  register_id: number
  title: string
  date: string
  start_time: string
  end_time: string | null
  description: string | null
  category: 'Voor de uitvaart' | 'Dag van de uitvaart' | 'Na de uitvaart'
  is_private: boolean
  external_link: string | null
  external_text: string | null
  image_id: number | null
  image_url: string | null
  location_id: number | null

  getFormattedDate(): string {
    return this.date.split('T')[0]
  }

  getFormattedStartTime(): string {
    return this.start_time.split('T')[1].substring(0, 5)
  }

  getFormattedEndTime(): string | null {
    return this.end_time ? this.end_time.split('T')[1].substring(0, 5) : null
  }

  constructor(copyFrom: FuneralInformationInterface) {
    this.id = copyFrom.id
    this.register_id = copyFrom.register_id
    this.title = copyFrom.title
    this.date = copyFrom.date
    this.start_time = copyFrom.start_time
    this.end_time = copyFrom.end_time
    this.description = copyFrom.description
    this.category = copyFrom.category
    this.is_private = copyFrom.is_private
    this.external_link = copyFrom.external_link
    this.external_text = copyFrom.external_text
    this.image_id = copyFrom.image_id
    this.image_url = copyFrom.image_url
    this.location_id = copyFrom.location_id
  }

  toPOJO(): FuneralInformationInterface {
    return {
      id: this.id,
      register_id: this.register_id,
      title: this.title,
      date: this.date,
      start_time: this.start_time,
      end_time: this.end_time,
      description: this.description,
      category: this.category,
      is_private: this.is_private,
      external_link: this.external_link,
      external_text: this.external_text,
      image_id: this.image_id,
      image_url: this.image_url,
      location_id: this.location_id
    }
  }
}

import type { Register } from '~/entities/register'
import { BaseResource } from '~/resources/base-resource'
import { ApiGetResponse } from '~/util/api-get-response'

export class RegisterResource extends BaseResource<Register> {
  constructor() {
    super('register', 'Register')
  }

  getWithInvitation(slug: string, invitation: string) {
    return useAsyncData(
      `register-${slug}-${invitation}`,
      () =>
        $fetch<{ item: Register; class: string }>(
          `${this.getApiUrl()}${slug}/with-invitation/${invitation}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          }
        ).then((response) => new ApiGetResponse<Register>(response.item, response.class)),
      {
        default: () => null
      }
    )
  }

  getForSharing(slug: string) {
    return useAsyncData(`register-share-${slug}`, () =>
      $fetch<{ item: Register; class: string }>(`${this.getApiUrl()}${slug}/share`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then((response) => new ApiGetResponse<Register>(response.item, response.class))
    )
  }
}

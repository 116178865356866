import { ApiFilterResponse } from '~/util/api-filter-response'
import { ApiGetResponse } from '~/util/api-get-response'

export default definePayloadPlugin(() => {
  definePayloadReducer(
    'ApiFilterResponse',
    (data) => data instanceof ApiFilterResponse && data.getSSRReducedResponse()
  )
  definePayloadReviver(
    'ApiFilterResponse',
    (data) => new ApiFilterResponse(data.items, data.number_of_items, data.total, data.class)
  )
  definePayloadReducer(
    'ApiGetResponse',
    (data) => data instanceof ApiGetResponse && data.getSSRReducedResponse()
  )
  definePayloadReviver('ApiGetResponse', (data) => new ApiGetResponse(data.item, data.class))
})

export interface StaffInterface {
  id: number
  funeral_company_id: number
  firstname: string
  lastname: string
  email: string
  phone: string | null
  profile_picture_id: number | null
  profile_picture_url: string | null
  active: boolean
  created_at: string
  updated_at: string
}

export class Staff implements StaffInterface {
  id: number
  funeral_company_id: number
  firstname: string
  lastname: string
  email: string
  phone: string | null
  profile_picture_id: number | null
  profile_picture_url: string | null
  active: boolean
  created_at: string
  updated_at: string

  constructor(copyFrom: StaffInterface) {
    this.id = copyFrom.id
    this.funeral_company_id = copyFrom.funeral_company_id
    this.firstname = copyFrom.firstname
    this.lastname = copyFrom.lastname
    this.email = copyFrom.email
    this.phone = copyFrom.phone
    this.profile_picture_id = copyFrom.profile_picture_id
    this.profile_picture_url = copyFrom.profile_picture_url
    this.active = copyFrom.active
    this.created_at = copyFrom.created_at
    this.updated_at = copyFrom.updated_at
  }

  toPOJO(): StaffInterface {
    return {
      id: this.id,
      funeral_company_id: this.funeral_company_id,
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      phone: this.phone,
      profile_picture_id: this.profile_picture_id,
      profile_picture_url: this.profile_picture_url,
      active: this.active,
      created_at: this.created_at,
      updated_at: this.updated_at
    }
  }
}

import type { AsyncData } from '#app'
import { useFetch, useRuntimeConfig } from '#app'
import { KeycloakService } from '~/service/keycloak'
import { ApiFilterResponse } from '~/util/api-filter-response'
import { ApiGetResponse } from '~/util/api-get-response'
import type { EntityPartial } from '~/util/entity-partial'
import { Filter, type FilterOptions } from '~/util/filter/filter'

export class BaseResource<T extends { toPOJO(): object }> {
  private config: {
    public: { apiUrl: string; basicAuth?: { username: string; password: string } }
  } = useRuntimeConfig() as {
    public: { apiUrl: string }
  }

  constructor(
    protected resourceName: string,
    protected entity: string
  ) {}

  list(filterOptions: FilterOptions): AsyncData<ApiFilterResponse<T>, unknown | null> {
    let url = this.getApiUrl()
    const filter = new Filter(filterOptions)
    url += `list?${filter.getQueryString()}`
    return useFetch(url, {
      headers: this.buildHeaders(),
      transform: (response: {
        items: T[]
        number_of_items: number
        total: number
      }): ApiFilterResponse<T> => {
        const result = new ApiFilterResponse<T>(
          response.items,
          response.number_of_items,
          response.total,
          this.entity
        )
        console.debug('BaseResource: filter result', result)
        return result
      }
    })
  }

  filter(filterOptions: FilterOptions): AsyncData<ApiFilterResponse<T>, unknown | null> {
    let url = this.getApiUrl()
    const filter = new Filter(filterOptions)
    url += `?${filter.getQueryString()}`
    return useFetch(url, {
      headers: this.buildHeaders(),
      transform: (response: {
        items: T[]
        number_of_items: number
        total: number
      }): ApiFilterResponse<T> => {
        const result = new ApiFilterResponse<T>(
          response.items,
          response.number_of_items,
          response.total,
          this.entity
        )
        console.debug('BaseResource: filter result', result)
        return result
      }
    })
  }

  get(id: string | number): AsyncData<ApiGetResponse<T>, unknown | null> {
    return useFetch(`${this.getApiUrl()}${id}`, {
      headers: this.buildHeaders(),
      transform: (response: T): ApiGetResponse<T> => {
        const result = new ApiGetResponse<T>(response, this.entity)
        console.debug('BaseResource: filter result', result)
        return result
      }
    })
  }

  create(body: EntityPartial<T>): AsyncData<ApiGetResponse<T>, unknown | null> {
    return useFetch(`${this.getApiUrl()}`, {
      headers: this.buildHeaders(),
      method: 'POST',
      body: body,
      transform: (response: T): ApiGetResponse<T> => {
        const result = new ApiGetResponse<T>(response, this.entity)
        console.debug('BaseResource: create result', result)
        return result
      }
    })
  }

  update(
    id: string | number,
    body: EntityPartial<T>
  ): AsyncData<ApiGetResponse<T>, unknown | null> {
    return useFetch(`${this.getApiUrl()}${id}`, {
      headers: this.buildHeaders(),
      method: 'PUT',
      body: body,
      transform: (response: T): ApiGetResponse<T> => {
        const result = new ApiGetResponse<T>(response, this.entity)
        console.debug('BaseResource: update result', result)
        return result
      }
    })
  }

  delete() {
    // todo
  }

  protected getApiUrl(): string {
    return `${this.config.public.apiUrl}/${this.resourceName}/`
  }

  private buildHeaders(): HeadersInit {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }

    const basicAuth = this.config.public.basicAuth
    if (basicAuth && basicAuth.username && basicAuth.password) {
      headers['Authorization'] = 'Basic ' + btoa(`${basicAuth.username}:${basicAuth.password}`)
    }

    if (window && window.location) {
      const token = KeycloakService.getInstance().getToken()
      if (token) {
        // We use X-Authorization because the backend is configured to use Authorization for basic auth and we don't want to change that
        headers['X-Authorization'] = `Bearer ${token}`
      }
    } else {
      console.warn(
        'BaseResource: Cannot add authorization token to call because window or window.location is not available'
      )
    }

    return headers
  }
}
